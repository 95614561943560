<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {equipmentQueryList} from '@/api/http.js'
export default {
  name: 'app',
  components: {
   
  },
  data() {
    return {
      
    }
  },
  computed: {
    equipmentList: {
      get() {
        return this.$store.state.equipmentList
      },
      set(val) {
        return this.$store.state.equipmentList = val
      }
    },
    Stdlist: {
      get() {
        return this.$store.state.Stdlist
      },
      set(val) {
        return this.$store.state.Stdlist = val
      }
    },
    Monlist: {
      get() {
        return this.$store.state.Monlist
      },
      set(val) {
        return this.$store.state.Monlist = val
      }
    },
    Maplist: {
      get() {
        return this.$store.state.Maplist
      },
      set(val) {
        return this.$store.state.Maplist = val
      }
    },
    Npclist: {
      get() {
        return this.$store.state.Npclist
      },
      set(val) {
        return this.$store.state.Npclist = val
      }
    }
  },
  methods:{
    equipmentQueryList(){
      equipmentQueryList().then((res) =>{
        if(res.code == 200){
          this.equipmentList = res.data.list
          const id = localStorage.getItem('v_id')
          var flag = false
          for(let i = 0;i < res.data.list.length;i++ ){
            if(res.data.list[i].code == id){
                return flag = true
            }
          }
          if(flag == false){
            localStorage.setItem('Stdlist','')
            localStorage.setItem('Monlist','')
            localStorage.setItem('Maplist','')
            localStorage.setItem('Npclist','')
            localStorage.setItem('v_id','')
            localStorage.setItem('v_name','')
            localStorage.setItem('equipment_url','')
          }
        }
      }).catch((err)=>{
        console.log(err)
      })
    },
    loadScript() {
      // 删除旧脚本（如果存在）
      // const existingScript = document.getElementById('kjk');
      // if (existingScript) {
      //   existingScript.remove();
      // }
      var timestamp = new Date().getTime();
      const script = document.createElement('script');
      const url = localStorage.getItem('equipment_url')
      script.id = 'kjk';
      script.src = url + "?v=" + timestamp;
      script.onload = () => {
        this.currentComponent = 'App'; // 加载成功后，设置当前组件为你要加载的组件
        if(localStorage.getItem('v_id')){
          this.Stdlist = JSON.parse(localStorage.getItem('Stdlist'))
          this.Monlist = JSON.parse(localStorage.getItem('Monlist'))
          this.Maplist = JSON.parse(localStorage.getItem('Maplist'))
          this.Npclist = JSON.parse(localStorage.getItem('Npclist'))
        }
        
      };
      if(url){
        document.head.appendChild(script);
        console.log('我是APP我执行了')
      }
    },
  },
  
  mounted(){
    this.equipmentQueryList()
  },
  created(){
    this.loadScript()
  },
 
}
</script>

<style>
a{
  text-decoration: none !important;
}
body{
  min-width: 1366px;
}
  /*按钮主题色  */
  .btn_color_down{
    background: #BF4949;
    transition: all 0.5s;
  }
  .btn_color_down:hover{
    transition: all 0.5s;
    filter: brightness(1.2);
  }
  .btn_color {
    transition: all 0.5s;
    background: #AC8747;
  }
  .btn_color:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
  }
  /* 全局配置message */
  .el-message{
    z-index: 9999999999 !important;
  }
  input{
    outline-style: none;
  }
  label{
    cursor: pointer;
  }
  .flex_box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a{
    cursor: pointer;
  }
  .lang_one{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lang_point {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
  }
  .text_ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
  }
 

</style>
