// 创建vuex中的store


import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'
Vue.use(Vuex);

// 准备actions,用于响应组件中的动作
const actions = {
    // login(context,value){
    //     context.commit('LOGIN',value)
    // },
    // login2(context,value){
    //     context.commit('LOGIN2',value)
    // }

}
// 准备mutations，用于操作数据（state）
const mutations = {
    setEquipmentList(state, payload) {
    // 在这里修改state中的数据
        state.data = payload
    }
}
// 准备state用于存储数据
const state = {
    equipmentList:'',
    v_id:'', //版本ID
    v_name:'',//版本名
    Stdlist:[],//物品信息
    Monlist:[],//怪物列表
    Maplist:[],//地图列表
    Npclist:[],//NPC列表
}
// geitters用于将state中的数据进行加工
const getters = {

}


// 创建并导出store
export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})