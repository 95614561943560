// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
import Home from '@/pages/Home.vue'
import Article from '@/components/Article.vue';
import Monster from '@/components/Monster.vue'
import Map from '@/components/Map.vue'
import Npc from '@/components/Npc.vue'
import {equipmentQueryList} from '@/api/http.js'

import store from '@/store'


// 解决：Uncaught (in promise) NavigationDuplicated; 
let originalPush = VueRouter.prototype.push; // 先保存一份 VueRouter.prototype.push方法
let originalRepace = VueRouter.prototype.replace;
 
VueRouter.prototype.push = function push(location) {
    // call(); 篡改上下文    catch(); 捕获异常 **** 
    return originalPush.call(this, location).catch(e => e);
}
VueRouter.prototype.replace = function replace(location) {
    return originalRepace.call(this, location).catch(e => e);

}

function equipmentQueryLists(){
    equipmentQueryList().then((res) =>{
        if(res.code == 200){
            store.commit('setEquipmentList',res.data.list)  
          const id = localStorage.getItem('v_id')
          console.log(id)
          var flag = false
          for(let i = 0;i < res.data.list.length;i++ ){
            if(res.data.list[i].code == id){
                return flag = true
            }
          }
          if(flag == false){
            localStorage.setItem('Stdlist','')
            localStorage.setItem('Monlist','')
            localStorage.setItem('Maplist','')
            localStorage.setItem('Npclist','')
            localStorage.setItem('v_name','')
            localStorage.setItem('v_id','')
            localStorage.setItem('equipment_url','')
          }
        }
      }).catch((err)=>{
        console.log(err)
      })
}

const router = new VueRouter({
    mode:'history',
    routes:[
        {
            path:'*',
            component:Home,
            meta:{title:'游戏查询'}
        },
        {
            path:'/home',
            component:Home,
            meta:{title:'游戏查询'},
        },
        {
            path:'/article',
            component:Article,
            meta:{title:'游戏查询'},
        },
        {
            path:'/monster',
            component:Monster,
            meta:{title:'游戏查询'},
        },
        {
            path:'/map',
            component:Map,
            meta:{title:'游戏查询'},
        },
        {
            path:'/npc',
            component:Npc,
            meta:{title:'游戏查询'},
        },
    ]
})
// 全局前置路由守卫——每次路由切换前、初始化时被调用
router.beforeEach((to,from,next)=>{
    if(to.path === '/home'){
        console.log('我要去首页')
        equipmentQueryLists()
        next()
    }else{
        next()
    }
})

// 全局后置路由守卫——每次路由切换后、初始化时被调用
router.afterEach((to,form)=>{
    // console.log('后置路由守卫')
    document.title = to.meta.title
})

export default router